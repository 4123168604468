import $ from 'jquery';
window.$ = $;

import Rails from '@rails/ujs';
window.Rails = Rails;
Rails.start();

import MicroModal from 'micromodal';
window.MicroModal = MicroModal;

var Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
window.Highcharts = Highcharts;

import 'select2';
import 'select2/dist/js/i18n/ja';

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

$(function () {
  // タブ
  $(".js-tab").on("click", function () {
    $(this).closest(".tab").find(".js-tab").removeAttr("data-active");
    $(this).attr("data-active", 1);
    $($(this).data("target")).hide();
    $(`${$(this).data("target")}[data-index='${$(this).data("index")}']`).fadeIn();
  });

  // 日付フィールド
  if ($(".js-date-field").length > 0) {
    $(".js-date-field").each(function () {
      var token = $(this).data("token");

      $(`[name='_year_${token}'], [name='_month_${token}'], [name='_day_${token}']`).on("change", function () {
        const year = $(`[name='_year_${token}']`).val()
        const month = $(`[name='_month_${token}']`).val()
        const day = $(`[name='_day_${token}']`).val()

        if (year.match(/^\d{4}$/) && month.match(/^\d{1,2}$/) && day.match(/^\d{1,2}$/)) {
          $(`.js-date-field[data-token="${token}"]`).val(`${year}-${month}-${day}`)
        } else {
          $(`.js-date-field[data-token="${token}"]`).val("")
        }
      })
    })
  }

  // 予算設定
  $("body").on("click", ".js-budget-submit", function () {
    var form, tr_element;
    form = $(this).closest(".js-budget-form");
    tr_element = $(this).closest("tr");
    form.find("input[name='id']").val(tr_element.find("input[name='id']").val());
    form.find("input[name='budget[month]']").val(tr_element.data("month"));
    form.find("input[name='budget[three_month_ago]']").val(tr_element.find("input[name='three_month_ago']").val());
    form.find("input[name='budget[two_month_ago]']").val(tr_element.find("input[name='two_month_ago']").val());
    form.find("input[name='budget[one_month_ago]']").val(tr_element.find("input[name='one_month_ago']").val());
    form.find("input[name='budget[zero_month_ago]']").val(tr_element.find("input[name='zero_month_ago']").val());
  });

  // 請求タイプ選択
  var setChargeForm = function(element, val, init = false) {
    if (val === "charge_type_portal_fix") {
      element.find(".js-charge-name").val("ポータル集客支援サービス 固定報酬").attr("disabled", 1);
      element.find(".js-charge-target").prop("disabled", true);
    } else if (val === "charge_type_portal_rate") {
      element.find(".js-charge-name").val("ポータル集客支援サービス 成果報酬").attr("disabled", 1);
      element.find(".js-charge-target").prop("disabled", false);
    } else if (val === "charge_type_portal_sales_rate") {
      element.find(".js-charge-name").val("ポータル集客支援サービス 売上比率").attr("disabled", 1);
      element.find(".js-charge-target").prop("disabled", true);
    } else if (val === "charge_type_firstee_fix") {
      element.find(".js-charge-name").val("Firstee集客支援サービス 固定報酬").attr("disabled", 1);
      element.find(".js-charge-target").prop("disabled", true);
    } else if (val === "charge_type_firstee_per") {
      element.find(".js-charge-name").val("Firstee集客支援サービス 送客").attr("disabled", 1);
      element.find(".js-charge-target").prop("disabled", true);
    } else if (val === "charge_type_firstee_per_visitor") {
      element.find(".js-charge-name").val("Firstee集客支援サービス ビジター送客").attr("disabled", 1);
      element.find(".js-charge-target").prop("disabled", true);
    } else if (val === "charge_type_firstee_per_member") {
      element.find(".js-charge-name").val("Firstee集客支援サービス メンバー送客").attr("disabled", 1);
      element.find(".js-charge-target").prop("disabled", true);
    } else if (val === "charge_type_firstee_rate") {
      element.find(".js-charge-name").val("Firstee集客支援サービス 成果報酬").attr("disabled", 1);
      element.find(".js-charge-target").prop("disabled", false);
    } else if (val === "charge_type_firstee_visitor_rate") {
      element.find(".js-charge-name").val("Firstee集客支援サービス ビジター成果報酬").attr("disabled", 1);
      element.find(".js-charge-target").prop("disabled", false);
    } else if (val === "charge_type_firstee_member_rate") {
      element.find(".js-charge-name").val("Firstee集客支援サービス メンバー成果報酬").attr("disabled", 1);
      element.find(".js-charge-target").prop("disabled", false);
    } else if (val === "charge_type_other") {
      if(!init) {
        element.find(".js-charge-name").val("").removeAttr("disabled");
      }
      element.find(".js-charge-target").prop("disabled", true);
    }
  }

  $(".js-charge-type").on("change", function () {
    setChargeForm($(this).closest(".js-charge"), $(this).val())
  });

  $(".js-charge-type").each(function(){
    setChargeForm($(this).closest(".js-charge"), $(this).val(), true)
  })

  // 請求合計金額計算
  if ($(".js-price").length > 0) {
    var total = 0;

    $(".js-price").each(function () {
      $(`.js-total[data-id='${$(this).data("id")}']`).text((parseInt($(`.js-total[data-id='${$(this).data("id")}']`).text().replaceAll(",", "").replaceAll("円", "")) + parseInt($(this).text().replaceAll(",", "").replaceAll("円", ""))).toLocaleString() + "円");
      total += parseInt($(this).text().replaceAll(",", "").replaceAll("円", ""));
    });

    $(".js-total-total").text(total.toLocaleString() + "円");
  }

  // select2
  $(".js-select2").select2({
    language: "ja"
  })
});
